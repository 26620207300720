const Colors = {
    YELLOW: "#fde700",
    GOLD: "#fdb716",
    GREEN: "#26b24b",
    DARK_GREEN: "#019848",
    BLUE: "#0d1f42",
    CORN_GREEN: "#049a2f",
    WHITE: "#ffffff",
    LIGHT_GREY: "#eaeaea",
    BLACK: "#000000",
    RED: "#f31c1c"
};

export default Colors;
